import { AfterContentInit, AfterViewInit, Component, DoCheck, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FunctionsService } from '../services/functions.service';
import { Merchant } from '../class/merchant';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AddressService } from '../services/address.service';
import { Address } from '../class/address';
import { ApiResponse } from '../class/apiResponse';
import { ApiService } from '../services/api.service';
import { CartService as CartService2 } from '../services/cart.service';
import { CartService } from '../services/new/cart.service';
import { LoginService } from '../services/login.service';
import { UserProfile } from '../class/user';
import { CartModalComponent } from '../cart/cart-modal/cart-modal.component';
import { ModalService } from '../services/new/modal.service';
import { Category } from '../class/category';
import { ItemService } from '../services/item.service';
import {ConfigService} from '../services/new/config.service';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.css']
})
export class NavHeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  merchant: Merchant;
  user: UserProfile;
  deliveryAddress: any;
  addressTmp: any;

  isLogin: boolean;

  changeCart$: Observable<string>;
  changeLogin$: Observable<string>;
  changeAddress$: Observable<boolean>;
  apiResponse$: Observable<ApiResponse>;
  loginService$: Observable<string>;

  load: boolean;
  passId: string;

  cartCount: string;

  inScrollDesk: boolean;
  inScrollMob: boolean;
  inScrollSearch:boolean;

  offsetTag: number;

  navCategory: Category[];

  constructor(
    public functions: FunctionsService,
    private modalService: ModalService,
    private ngbModalService: NgbModal,
    private config: NgbModalConfig,
    public service: ApiService,
    private addressService: AddressService,
    private loginService: LoginService,
    public cartService: CartService,
    private itemService: ItemService,
    public configService?: ConfigService
  ) {
    this.load = false;
    this.cartCount = this.functions.getItemLocal('cartCount') ? this.functions.getItemLocal('cartCount') : null;
    this.changeCart$ = new Observable<string>();
    this.changeLogin$ = new Observable<string>();
    this.changeAddress$ = new Observable<boolean>();
    this.apiResponse$ = new Observable<ApiResponse>();
    this.loginService$ = new Observable<string>();
    this.navCategory = [];
  }

  ngOnInit(): void {
    this.merchant = new Merchant();
    this.deliveryAddress = new Address();
    this.addressTmp = [];
    this.getMerchantInfo();
    this.getUserInfo();
    this.isLogin = this.functions.isLogin();
    this.loadTmpAddress();
    this.offsetTag = 0;

    this.changeAddress$ = AddressService.addressEmitter;
    this.changeAddress$.subscribe(data => {
      if (data) {
        this.deliveryAddress = this.functions.getItemLocal('deliveryAddress')
          ? JSON.parse(this.functions.getItemLocal('deliveryAddress')) : '';
        this.verifyAddressDelivery();
        this.loadTmpAddress();
      }
    });

    if (this.addressService.verifyAddressDelivery()) {
      AddressService.addressEmitter.emit(true);
    }
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll(): void {
    this.inScrollDesk = window.pageYOffset >= 223 && window.innerWidth > 576;
    this.inScrollMob = window.pageYOffset >= 234 && window.innerWidth < 576;
    this.inScrollSearch = window.pageYOffset >= 279 && window.innerWidth > 576;

    // console.log(window.pageYOffset)
  }

  ngAfterViewInit(): void {
    if (!this.addressService.verifyAddressDelivery()) {
      this.config.keyboard = false;
      AddressService.showInfoMerchant = true;
      // this.modalService.openAddress();
    }
  }

  getUserInfo(): void {
    if (this.functions.getItemLocal('userProfile')) {
      this.user = JSON.parse(this.functions.getItemLocal('userProfile'));
    }
    else {
      this.user = new UserProfile();
    }
  }

  loadTmpAddress(): void {
    if (this.functions.getItemLocal('AddressTmp')) {
      this.addressTmp = JSON.parse(this.functions.getItemLocal('AddressTmp'));
    }
  }

  verifyAddressDelivery(): void {
    if (this.functions.getItemLocal('deliveryAddress')) {
      this.deliveryAddress = JSON.parse(this.functions.getItemLocal('deliveryAddress'));
      this.config.backdrop = true;
      this.config.keyboard = true;
    }
    else {
      this.config.keyboard = false;
      // this.modalService.openAddress();
    }
  }

  changeAddress(address): void {
    this.load = true;
    for (const [k, v] of Object.entries(this.addressTmp)) {
      const ad: any = v;
      if (ad.address === address) {
        this.apiResponse$ = this.service.checkDistanceMerchant(ad);
        this.apiResponse$.subscribe(data => {
          if (data.code === 1) {
            this.cartService.deliveryFee = Number(data.details.delivery_fee) === 0 ? 'Taxa de Entrega Grátis' : data.details.delivery_fee;
            console.log(this.cartService.deliveryFee);
            this.cartService.deliveryEstimation = data.details.delivery_estimation;
            this.functions.setItemLocal('distanceInfo', JSON.stringify(data.details));
            this.functions.setItemLocal('deliveryCharge', data.details.delivery_fee);
            this.functions.setItemLocal('deliveryEstimation', data.details.delivery_estimation);
            this.functions.setItemLocal('deliveryAddress', JSON.stringify(ad));
            this.apiResponse$ = this.service.changeAddressCart(ad);
            this.apiResponse$.subscribe(change => {
              if (change.code === 1) {
                this.verifyAddressDelivery();
                this.cartService.loadCart();
                this.addressService.changeAddress();
              }
            },
              error => { this.functions.log(error); },
              () => {
                this.load = false;
              });
          }
        });
        break;
      }
    }
  }

  logout(): void {
    this.loginService.logout();
  }

  getMerchantInfo(): void {
    if (this.functions.getItemLocal('merchantInfo')) {
      this.merchant = JSON.parse(this.functions.getItemLocal('merchantInfo'));
    }
  }

  openModalCart(): void {
    this.ngbModalService.open(CartModalComponent);
  }

  ngOnDestroy(): void {
    delete this.changeCart$;
    delete this.changeLogin$;
    delete this.changeAddress$;
    delete this.apiResponse$;
    delete this.loginService$;
  }
}

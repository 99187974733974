<!-- MODAL HEADER -->

<div class="modal-header-mechant">
    <div class="logo-image-merchant rounded mr-2 brand-wrap ">
        <img src="{{merchant.optionsMerchant.logo_app}}">
    </div>
    <h4 id="exampleModalLabel">{{merchant.merchantName}}</h4>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true"><i class="feather-x"></i></span>
    </button>
</div>

<div class="modal-body bg-light pb-4 p-3">
    <div class="box shadow-sm rounded bg-white mb-3 p-3">
        <h5 class="text-dark d-flex"><i class="feather-message-circle h5 text-primary mr-2"></i>
            <span>Informações</span>
        </h5>
        <p class="mb-0 text-muted">{{merchant.information}}</p>
    </div>
    <div class="box shadow-sm rounded bg-white mb-3 p-3">
        <h5 class="text-dark d-flex"> <i class="feather-dollar-sign h5 text-primary mr-2"></i> 
            <span>Opções de pagamento</span>
        </h5>
        <p class="mb-1 mt-3" *ngFor="let payment of merchant.paymentList | keyvalue">
            <i class="feather-chevrons-right icon-color-02"></i>
                {{payment.value}}
            <!--EXIBIÇÃO DAS BANDEIRAS DE CARTÃO-->
            
            <!--FIM DAS BANDEIRAS-->
        </p>
    </div>
    <div class="box shadow-sm rounded bg-white mb-3 p-3">
        <h5 class="text-dar d-flex"><i class="feather-clock h5 text-primary mr-2"></i> 
            <span>Horário de {{merchant.isHoursServiceDifferent ? 'Entrega' : 'Funcionamento'}}</span> </h5>
        <p class="mb-1" *ngFor="let open of merchant.hours_delivery_list">
            <i class="feather-chevrons-right icon-color-02"></i> {{open.day}} 
            <span class="float-right text-dark" *ngIf="open.hours"> {{open.hours}} </span>
            <span class="float-right badge badge-danger" *ngIf="!open.hours">FECHADO</span>
        </p>
    </div>

    <div class="box shadow-sm rounded bg-white mb-3 p-3" *ngIf="merchant.isHoursServiceDifferent">
        <h5 class="text-dar d-flex"><i class="feather-clock h5 text-primary mr-2"></i>
            <span>Horário de Retirada</span> </h5>
        <p class="mb-1" *ngFor="let open of merchant.hours_pickup_list">
            <i class="feather-chevrons-right icon-color-02"></i> {{open.day}}
            <span class="float-right text-dark" *ngIf="open.hours"> {{open.hours}} </span>
            <span class="float-right badge badge-danger" *ngIf="!open.hours">FECHADO</span>
        </p>
    </div>
    <h6 class="text-dark m-2 pt-3">Onde estamos</h6>


</div>

<div class="modal-footer">
    <div class="col-12 p-0 m-0 bg-primary">
        <button type="button" class="btn btn-block btn-lg" (click)="closeModal()"> <i class="feather-x-circle"></i> Fechar</button>
    </div>
</div>

import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ApiResponse } from '../class/apiResponse';
import { Merchant } from '../class/merchant';
import { Category } from '../class/category';
import { ApiService } from '../services/api.service';
import { Observable } from 'rxjs';
import { NgbModal, NgbRatingConfig, NgbTimepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { FunctionsService } from '../services/functions.service';
import { Page } from '../class/page';
import { group } from '@angular/animations';
import { PageScrollConfig } from 'ng2-page-scroll';
import { AddressService } from '../services/address.service';
import { CartService } from '../services/new/cart.service';
import { ModalService } from '../services/new/modal.service';
import { MenuService } from '../menu/menu.service';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import { CartModalComponent } from '../cart/cart-modal/cart-modal.component';
import { ItemService } from '../services/item.service';
import { conditionallyCreateMapObjectLiteral } from '@angular/compiler/src/render3/view/util';
import { cloneDeep } from 'lodash';
import {HideService} from '../services/hide.service';
import {ColorsDefault} from '../class/colors';
import {MerchantService} from '../services/merchant.service';
import {ConfigService} from '../services/new/config.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit, AfterViewInit {

  @ViewChild('tagMenu') tagMenu?: ElementRef;
  @ViewChildren('titleCat') titleCat: QueryList<ElementRef>;
  apiResponse: ApiResponse;
  merchant: Merchant;
  merchant$: Observable<ApiResponse>;

  category: Category[];
  filteredItems: Category[];

  category$: Observable<ApiResponse>;
  service$: Observable<ApiResponse>;
  addressEvent$: Observable<boolean>;
  cartEvent$: Observable<string>;

  passId: string;
  cardList: any;

  pagesGeneric: Page;
  pagesGeneric$: Observable<ApiResponse>;

  tmp: any[];
  locationMerchant: any;
  ratings: number;
  listCategory: NodeList;
  listCategoryA: NodeList;
  refCategory: any[];
  refCategoryFixed: any[];
  stc = true;
  count: number;
  daysWeek = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
  hoursToday: string;
  cartCount: string;
  dateNow: Date;

  deliveryCharge: any;
  deliveryEstimation: any;
  deliveryAddress: any;
  deliveryFee: any;
  distanceInfo: any;

  open: boolean;
  closeText: string;
  closeHour: string;
  loadingHours: boolean;

  visit: boolean;

  whatsapp: string;
  msgWhats: string;

  itemPromo: any;
  photos = {};
  currentSection = '';

  isSticky: boolean;
  offsetTag: number;
  isMobile: boolean;

  token: string;
  slug: string;

  searchProduct: string;

  eventHide$: Observable<boolean>;
  color: ColorsDefault;
  colorDefault: ColorsDefault;
  colorApp: ColorsDefault;

  action: string;
  ready: boolean;

  hide: boolean;
  hideFooter: boolean;

  orderId: any;


  @ViewChild('loading') loading: boolean;

  constructor(
    public service: ApiService,
    private rat: NgbRatingConfig,
    private ModalService: NgbModal,
    public modalService: ModalService,
    public functions: FunctionsService,
    public cartService: CartService,
    private serviceModal: ModalService,
    private menuService: MenuService,
    private addressService: AddressService,
    private metaService: Meta,
    private readonly titleService: Title,
    private merchantService: MerchantService,
    private routeActive?: ActivatedRoute,
    public itemService?: ItemService,
    private router?: Router,
    private ngbModalService?: NgbModal,
    public configService?: ConfigService
  ) {
    this.functions.setItemLocal('step', '');
    this.merchant = new Merchant();
    rat.max = 5;
    rat.readonly = true;
    this.dateNow = new Date();
    this.itemPromo = [];
    this.category = [];
    this.passId = '';
    this.filteredItems = [];
    this.hide = false;
    this.eventHide$ = new Observable<boolean>();
    this.loadingHours = false;
    this.slug = this.routeActive.snapshot.params[`slug`];
    this.token = this.routeActive.snapshot.params[`token`];
  }


  ngOnInit(): void {

    this.orderId = this.functions.getItemLocal('orderId');
    if (this.token) {
      this.service.changeToken(this.token);
    }
    else
    {
      this.service.changeToken(this.service.generateToken());
    }

    if (this.slug) {
      this.service.changeSlug(this.slug);
    }

    this.loadMerchantInfo();
    this.configService.getConfigCatalog();

    if (!this.functions.getItemLocal('transaction_type'))
    {
      this.functions.setItemLocal('transaction_type', 'delivery');
    }

    this.addressService.deliveryAddress = this.functions.getItemLocal('deliveryAddress') ? JSON.parse(this.functions.getItemLocal('deliveryAddress')) : false;
    this.addressService.distanceInfo = this.functions.getItemLocal('distanceInfo') ? JSON.parse(this.functions.getItemLocal('distanceInfo')) : false;
    this.addressService.deliveryCharge = this.functions.getItemLocal('deliveryCharge');


    this.offsetTag = 0;
    this.addressEvent$ = AddressService.addressEmitter;
    this.addressEvent$.subscribe(data => {
      if (data) {
        this.distanceInfo = this.functions.getItemLocal('distanceInfo') ? JSON.parse(this.functions.getItemLocal('distanceInfo')) : '';
        this.deliveryCharge = this.functions.getItemLocal('deliveryCharge') ? this.functions.getItemLocal('deliveryCharge') : false;
        this.deliveryEstimation = this.functions.getItemLocal('deliveryEstimation') ? this.functions.getItemLocal('deliveryEstimation') : false;
        this.deliveryFee = this.functions.getItemLocal('deliveryCharge') ? this.functions.getItemLocal('deliveryCharge') : false;
        console.log(this.deliveryFee);
      }
    });
    PageScrollConfig.defaultScrollOffset = 50;
    PageScrollConfig.defaultDuration = 700;
    this.msgWhats = 'Olá, estou no APP de vocês e gostaria de informações';
    this.loadPaymentCardList();
    this.loadService();
    this.getPagesGeneric();
  }
  @HostListener('window:scroll', ['$event'])
  checkScroll(): void {
    this.isSticky = window.pageYOffset >= 200 + this.offsetTag;
  }

  @HostListener('window:resize', ['$event'])
  resizeScreen(event): void {
    /*console.log(window.innerWidth);*/
    this.isMobile = window.innerWidth < 730;
  }

  ngAfterViewInit(): void {
    this.offsetTag = this.tagMenu.nativeElement.offsetTop;
  }

  openModalDetailsOrder(modal, id): void
  {
    this.functions.setItemLocal('orderId', id);

    this.ngbModalService.open(modal);
  }

  loadMerchantInfo(): void
  {
    this.service$ = this.service.getMerchantInfo(this.slug);
    this.service.loading = true;
    this.service$.subscribe(data => {
      this.service.loading = false;
      console.log(data);
      if (data.code === 1)
      {
        this.merchant = data.details.data;
        if (this.merchant.optionsMerchant.custom_color === '1')
        {
          this.color = this.colorApp;
        }
        else
        {
          this.color = this.colorDefault;
        }
        // Adicionar informações SEO
        this.titleService.setTitle(`${this.merchant.merchantName} - Click Mix Delivery`);
        this.metaService.addTags([
          {name: 'title', content: `${this.merchant.merchantName} - ${this.merchant.seo?.title}`},
          {name: 'description', content: this.merchant.seo?.description ? this.merchant.seo.description : this.merchant.information},
          {name: 'keywords', content: this.merchant.seo?.keywords},
          {name: 'type', content: this.merchant.cuisine},
          {name: 'image', content: this.merchant.backgroundImage},
          {name: 'image:secure_ur', content: this.merchant.logo1},
          {name: 'image:alt', content: `Logo da ${this.merchant.merchantName}`},
          {name: 'og:title', content: `${this.merchant.merchantName} - ${this.merchant.seo?.title}`},
          {name: 'og:keywords', content: this.merchant.seo?.keywords},
          {name: 'og:image', content: this.merchant.logo1},
          {name: 'og:image:secure_ur', content: this.merchant.logo1},
          {name: 'og:image:alt', content: `Logo da ${this.merchant.merchantName}`},
          {name: 'og:type', content: this.merchant.cuisine},
          {name: 'og:description', content: this.merchant.seo?.description ? this.merchant.seo.description : this.merchant.information},
          {charset: 'UTF-8'}
        ]);


        this.cartService.loadCart();

      }
      else if (data.code === 11)
      {
        this.functions.showAlertError('Estabelecimento Expirado', 'Entre em contato com um administrador');
      }
      else if (data.code === 10 || data.code === 22)
      {
        //this.functions.redirect('/not-found');
      }
      else
      {
        console.log(data);
        let errorInit = 1;
        const errorInitLocal = this.functions.getItemLocal('errorInit');
        if (errorInitLocal)
        {
          errorInit = errorInit + Number(errorInitLocal);
        }
        this.functions.setItemLocal('erroInit', errorInitLocal.toString());
        this.functions.showAlertError('Houve um erro no servidor!', 'O app será reinicializado');

      }
    }, error => {
      this.service.loading = false;
      console.log(error);
      let errorInit = 1;
      const errorInitLocal = this.functions.getItemLocal('errorInit');
      if (errorInitLocal)
      {
        errorInit = errorInit + Number(errorInitLocal);
      }
      this.functions.setItemLocal('erroInit', errorInit.toString());
      this.functions.showAlertError('Houve um erro com a conexão do servidor!', 'O app será reinicializado');
    }, () => {

      this.service.loading = false;
      /*ARMAZENANDO INFO DO MERCHANT*/
      this.service$ = this.service.getServicesList();
      this.service$.subscribe(data => {
        if (data.code === 1)
        {
          this.merchant.services = data.details.data;

        }

      }, error => {

      }, () => {
        this.functions.setItemLocal('merchantInfo', JSON.stringify(this.merchant));
        this.merchant = JSON.parse(this.functions.getItemLocal('merchantInfo'));
        this.locationMerchant = { lat: Number(this.merchant.latitude), lng: Number(this.merchant.longitude) };
        this.ratings = parseFloat(this.merchant.ratings.ratings);
        this.deliveryCharge = this.functions.getItemLocal('deliveryCharge') ? this.functions.getItemLocal('deliveryCharge') : false;
        this.deliveryEstimation = this.merchant.deliveryEstimation;
        console.log('open hours');
        console.log(this.merchant.optionsMerchant);
        this.verifyHoursMerchant(this.merchant.openingHours);

        if (this.merchant.whatsapp === '1') {
          this.whatsapp = `https://api.whatsapp.com/send?phone=+55${this.merchant.contactPhone}&text=${this.msgWhats}`;
        }
        else {
          this.whatsapp = this.functions.sanitizerLink('javascript:;');
        }
        this.merchantService.info = this.merchant;
        this.service.loadDefaultColor();
      });

    });
  }

  verifyLogin(): void
  {
    if (!this.functions.isLogin() && !this.functions.getItemLocal('token'))
    {
      const token = 'visit_' + this.functions.getUUID('visit');
      this.functions.setItemLocal('token', token);
      this.service.changeToken(token);
    }
  }


  showPageCustom(identify): void {
    this.functions.redirectPageCustom(identify);
    this.ngbModalService.dismissAll();
  }

  onSectionChange(sectionId: string): void {
    this.currentSection = sectionId;
    const groupCat = document.querySelector(`#category_header`) as HTMLElement;
    const cat = document.querySelector(`#category_header .${sectionId}`) as HTMLElement;
    /* if (this.isMobile)
    {
      cat.scrollIntoView();
    }*/
  }

  scrollPage(element: string, offset: number = 0): void {
    this.currentSection = '';
    const yOffset = -offset;
    const e = document.querySelector('#' + element).getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: e, behavior: 'smooth' });
    /*document.querySelector(element).scrollIntoView({behavior: 'smooth'});*/
  }

  getPagesGeneric(): void {
    this.pagesGeneric$ = this.service.get('getPagesGenericAll');
    this.pagesGeneric$.subscribe(data => {
      if (data.code === 1) {
        this.pagesGeneric = data.details.data;
      }
    });
  }

  asIsOrder(a, b): number {
    return 1;
  }

  verifyProductschedule(v: any): boolean {
    const today = new Date();
    const dateNow = this.functions.getDate() + ' ' + this.functions.getDate(true);
    const nowTime = new Date(dateNow).getTime();
    const initTime = new Date(this.functions.getDate() + ' ' + v.prog_init_hour).getTime();
    const endTime = new Date(this.functions.getDate() + ' ' + v.prog_end_hour).getTime();
    if (v.prog_day) {
      if (v.prog_day.includes(today.getDay()) && (v.prog_init_hour !== '00:00:00' || v.prog_end_hour !== '00:00:00')) {
        return (Number(nowTime) > Number(initTime) && Number(nowTime) < Number(endTime));
      }
      else if (v.prog_day.includes(today.getDay()) && (v.prog_init_hour === '00:00:00' && v.prog_end_hour === '00:00:00')) {
        return true;
      }
    }
    else if (v.prog_init_hour !== '00:00:00' || v.prog_end_hour !== '00:00:00') {
      return (Number(nowTime) > Number(initTime) && Number(nowTime) < Number(endTime));
    }
    else {
      return true;
    }
  }


  loadService(): void {
    if (this.functions.getItemLocal('merchantInfo'))
    {
      /*console.log(typeof this.merchant.services);*/
    }
    else
    {
    }

    this.category = [];
    this.category$ = this.service.getCategoryItens();
    this.category$.subscribe(data => {
          console.log(data);
          this.apiResponse = data;
          this.category = this.apiResponse.details.data;
          this.category = this.category.filter( (a, b) => {
            if (a.items)
            {
              return a;
            }
          });

          for (const [key, value] of Object.entries(this.category))
          {
            this.category[key].count = value.items.length;
            this.category[key].items =  this.category[key].items.filter((a: any, b: any) => {
              if (this.verifyProductschedule(a))
              {
                return a;
              }
            });

            for (const [k, v] of Object.entries(value.items ? value.items : ''))
            {
              this.photos[value.items[k].item_id] = value.items[k].photo;
              if (v.prices && v.prices[0].discount_price)
              {
                this.itemPromo.push(v);
              }
            }
          }

          this.category = this.category.filter( (a, b) => {
            a.items.filter((c: any, d: any) => {
              if (this.verifyProductschedule(c))
              {
                return c;
              }
            });
            if (a.items.length)
            {
              return a;
            }
          });

          this.functions.setItemLocal('photos_items', JSON.stringify(this.photos));
        }, error => {},
        () => {
          this.refCategoryFixed = this.refCategory;
        });

    this.service.loading = false;
  }

  checkAllItemDate(): void {
    for (const [iCat, cat] of Object.entries(this.category)) {
      if (cat && cat.items) {
        for (const [iItem, item] of Object.entries(cat.items)) {
          if (this.verifyItemDate(item)) {
            delete this.category[iCat].items[iItem];
          }
        }
        this.category[iCat].items.filter((value) => {
          return value != null;
        });
      }
    }
  }

  verifyItemDate(item): boolean {
    const currentDate = new Date();
    let response = true;
    const startDate = new Date(currentDate.getTime());
    const endDate = new Date(currentDate.getTime());


    startDate.setHours(item.prog_init_hour.split(':')[0]);
    startDate.setMinutes(item.prog_init_hour.split(':')[1]);
    startDate.setSeconds(item.prog_init_hour.split(':')[2]);

    endDate.setHours(item.prog_end_hour.split(':')[0]);
    endDate.setMinutes(item.prog_end_hour.split(':')[1]);
    endDate.setSeconds(item.prog_end_hour.split(':')[2]);

    item.prog_day = item.prog_day !== '' ? JSON.parse(item.prog_day) : null;
    this.functions.log(item.prog_day);
    if (item.prog_day && item.prog_day.length) {

      /*this.functions.log('esta no dia? ' + item.prog_day.includes(currentDate.getDay().toString()));
      this.functions.log('esta no horario? ' + (startDate < currentDate && endDate > currentDate));
      this.functions.log(item.prog_day);*/
      if (item.prog_day.includes(currentDate.getDay().toString())) {
        if (item.prog_init_hour !== item.prog_end_hour) {
          if (startDate < currentDate && endDate > currentDate) {
            /*this.functions.log('horario e dia');*/
            response = false;
          }
          else {
            response = true;
          }
        }
        else {
          response = false;
        }

        /*console.log('dia');
        response = false;
        console.log('response');
        console.log(response);*/
      }
      /*console.log('result hour');
      console.log(currentDate);
      console.log(startDate);
      console.log(endDate);
      console.log();*/
    }
    /* console.log(item.prog_day);
     console.log(currentDate.getDay());
     console.log(response);*/
    return response;
  }



  loadPaymentCardList(): void {
    this.service$ = this.service.get('getPayondeliverycards');
    this.service$.subscribe(data => {
      if (data.code === 1) {
        this.cardList = data.details.data;
      }
    });
  }

  openEdititem(catId: string, itemId: string): void {
    this.menuService.catId = catId;
    this.menuService.itemId = itemId;
    this.serviceModal.openItemModal();
  }

  openModal(modal): void {
    this.ngbModalService.open(modal);
  }

  openModalInfoMerchant(): void {
    this.modalService.openInfoMerchant();
  }

  closeModal(): void {
    this.ngbModalService.dismissAll();
  }

  convertNumber(arg: string): number {
    return Number(arg);
  }

  verifyHoursMerchant(hours): void {
    this.loadingHours = true;
    const dateTime = new Date();
    const daysOfWeek = {
      monday: 'Segunda-Feira',
      tuesday: 'Terça-Feira',
      wednesday: 'Quarta-Feira',
      thursday: 'Quinta-Feira',
      friday: 'Sexta-Feira',
      saturday: 'Sábado',
      sunday: 'Domingo'
    };

    let dateToday = Number(dateTime.getMonth()) < 10
      ? '0' + (Number(dateTime.getMonth()) + 1) + '/'
      : Number(dateTime.getMonth()) + 1 + '/';
    dateToday += Number(dateTime.getDate()) < 9 ? '0' + dateTime.getDate() + '/' : dateTime.getDate() + '/';
    dateToday += dateTime.getFullYear();

    const dateStart = `${dateToday} ${hours.start_time}:00`;
    const dateEnd = `${dateToday} ${hours.end_time}:00`;


    if (hours?.day === dateTime.toLocaleDateString('en', { weekday: 'long' }).toLowerCase()) {
      if (new Date() > new Date(dateStart) && new Date() < new Date(dateEnd)) {
        this.open = true;
        this.hoursToday = `${hours.start_time} - ${hours.end_time}`;
      }
      else if (new Date() < new Date(dateStart)) {
        this.closeText = 'Abrirá hoje';
        this.closeHour = `${hours.start_time} - ${hours.end_time}`;
      }
      else {
        this.closeText = `Abrirá na próxima ${daysOfWeek[hours.day]}`;
        this.closeHour = `${hours.start_time} - ${hours.end_time}`;
        this.open = false;
      }
    }
    else {
      this.closeText = `Abrirá ${daysOfWeek[hours.day]}`;
      this.closeHour = `${hours.start_time} - ${hours.end_time}`;
      this.open = false;
    }
    this.loadingHours = false;
    this.functions.setItemLocal('open', this.open ? '777' : '0');
  }

  getHoursMerchant(hoursMerchant): void {
    if (Object.entries(hoursMerchant).length) {
      for (const [k, v] of Object.entries(hoursMerchant)) {
        // @ts-ignore
        if (v.day === this.daysWeek[this.dateNow.getDay()]) {
          const hourNow = `${('0' + this.dateNow.getHours()).slice(-2)}:${String(this.dateNow.getMinutes()).padStart(2, '0')}`;
          // @ts-ignore
          if (v.hours) {
            // @ts-ignore
            const tmp = v.hours.split('/');
            const am = tmp[0] ? tmp[0] : '';
            const pm = tmp[1] ? tmp[1] : '';
            if (am.split('-')[0].trim() <= hourNow && am.split('-')[1].trim() > hourNow) {
              this.open = true;
              this.hoursToday = am;
              break;
            }
            else if (am.split('-')[0].trim() > hourNow) {
              this.open = false;
              this.closeText = 'ABRIRÁ HOJE';
              this.closeHour = am;
              break;
            }
            else {
              if (pm) {
                this.functions.log(pm.split('-')[0].trim() < hourNow);
                if (pm.split('-')[0].trim() <= hourNow && pm.split('-')[1].trim() > hourNow) {
                  this.functions.log('Aberto Segundo Horario');
                  this.open = true;
                  this.hoursToday = pm;
                  break;
                }
                else {
                  this.closeText = 'Abrirá hoje';
                  this.closeHour = pm;
                  break;
                }
              }
              else {
                this.closeText = 'Abrirá ' + hoursMerchant[Number(k) + 1].day;
                this.closeHour = hoursMerchant[Number(k) + 1].hours;
                this.open = false;
                break;
              }
            }
            this.closeText = 'Abrirá ' + hoursMerchant[Number(k) + 1].day;
            this.closeInfo(hoursMerchant, k);
            this.open = false;
          }
          else {
            this.closeText = 'Abrirá ' + hoursMerchant[Number(k) + 1].day;
            this.closeInfo(hoursMerchant, k);
            this.open = false;
            break;
          }
        }
        else {
          this.open = false;
          this.closeInfo(hoursMerchant, k);
        }
      }
    }
    else {
      this.open = false;
      this.closeText = `Entre em contato com o estabelecimento`;
      this.closeHour = `Não existe horários cadastrados`;
    }

    this.service$ = this.service.getMerchantCloseStore();
    this.service$.subscribe(data => {
      if (data.code === 1) {
        this.open = false;
        this.closeText = 'Estabelecimento fechado';
        this.functions.setItemLocal('open', this.open ? '777' : '0');
        if (!this.open) {
          this.functions.setItemLocal('txtClose', JSON.stringify({ closeText: this.closeText, closeHours: this.closeHour }));
        }
      }
      else {
        this.functions.setItemLocal('open', this.open ? '777' : '0');
        if (!this.open) {
          this.functions.setItemLocal('txtClose', JSON.stringify({ closeText: this.closeText, closeHours: this.closeHour }));
        }
      }
    });

  }

  getHoursMerchantBackup(hoursMerchant): void {
    let hourInit = '';
    let hourClose = '';
    for (const [key, value] of Object.entries(hoursMerchant)) {
      this.merchant.openingHours[key].hours = this.merchant.openingHours[key].hours.split('&')[0];
      // @ts-ignore
      if (value.day === this.daysWeek[this.dateNow.getDay()]) {
        const hourNow = `${('0' + this.dateNow.getHours()).slice(-2)}:${String(this.dateNow.getMinutes()).padStart(2, '0')}`;
        const h: any = value;

        if (h.hours) {
          this.hoursToday = h.hours.split('&')[0];
          hourInit = this.hoursToday.split('-')[0].trim();
          hourClose = this.hoursToday.split('-')[1].trim();
          if (hourNow > hourInit && hourNow < hourClose) {
            this.open = true;
          }
          else {
            this.open = false;
            if (hourNow < hourInit) {
              this.closeText = 'Abrirá Hoje';
              this.closeHour = this.hoursToday;
            }
            else if (hourNow >= hourClose) {
              this.closeText = 'Abrirá ';
              this.closeInfo(hoursMerchant, key);
            }
          }

        }
        else {
          this.closeText = 'Abrirá ';
          this.closeInfo(hoursMerchant, key);
        }
      }
    }
  }

  closeInfo(hoursMerchant, key): void {

    for (const [i, h] of Object.entries(hoursMerchant)) {
      if (key === i && hoursMerchant[i].hours) {
        this.functions.log('IF horario');
        this.closeText += `Abrirá ${hoursMerchant[i].day}`;
        this.closeHour = `${hoursMerchant[i].hours.split('/').join(' ')}`;
        break;
      }
      else if (i > key && hoursMerchant[i].hours) {
        let firstDay = true;

        this.functions.log(hoursMerchant[i].hours);
        this.closeText = `Abrirá ${hoursMerchant[i].day}`;
        this.closeHour = `${hoursMerchant[i].hours.split('/').join(' ')}`;
        firstDay = false;
        break;
      }
      else {
        for (const [q, t] of Object.entries(hoursMerchant)) {
          if (t[`hours`]) {
            this.closeText = `Abrirá ${t[`day`]}`;
            console.log('verificando se existe horario');
            break;
          }
          break;
        }
      }
    }
  }


  showRoute(modal): void {
    this.ngbModalService.open(modal);
    this.initMap();
  }

  initMap(): void {
    const directionService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer();
    const map = new google.maps.Map(document.getElementById('map2') as HTMLElement,
      {
        zoom: 10,
        center: { lat: Number(this.merchant.latitude), lng: Number(this.merchant.longitude) }
      }
    );

    directionsRenderer.setMap(map);

    this.calculateAndDisplayRoute(directionService, directionsRenderer);
  }

  calculateAndDisplayRoute(
    directionsService: google.maps.DirectionsService,
    directionsRenderer: google.maps.DirectionsRenderer): void {
    let addressInit = '';
    let tmpAddress: any = '';

    if (this.functions.getItemLocal('deliveryAddress')) {
      tmpAddress = this.functions.getItemLocal('deliveryAddress') ? JSON.parse(this.functions.getItemLocal('deliveryAddress')) : '';
      if (tmpAddress) {
        addressInit = `${tmpAddress.street}, ${tmpAddress.number} - ${tmpAddress.bairro} - ${tmpAddress.city} - ${tmpAddress.state}`;
      }
    }
    const waypts: google.maps.DirectionsWaypoint[] = [];

    directionsService.route({
      origin: addressInit,
      waypoints: waypts,
      optimizeWaypoints: true,
      destination: this.merchant.address,
      travelMode: google.maps.TravelMode.DRIVING
    }, (response, status) => {
      if (status === 'OK') {
        directionsRenderer.setDirections(response);
      }
    });
  }

  openModalCart(): void {
    this.ngbModalService.open(CartModalComponent);
  }
}

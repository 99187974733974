import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {FunctionsService} from './functions.service';

@Injectable({
  providedIn: 'root'
})
export class MerchantService {

  info: any;
  constructor(
      private apiService: ApiService,
      private functions: FunctionsService
  ) { }

  verifyInfoMerchant(): void
  {
    if (!this.info)
    {
      if (!this.info)
      {
        this.info = this.functions.getItemLocal('merchantInfo');
        if (this.info)
        {
          this.info = JSON.parse(this.info);
          this.apiService.loadColorApp();
        }
      }
    }
  }
}

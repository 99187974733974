<!-- Menu Footer BAR-->
<div class="osahan-menu-fotter fixed-bottom bg-white px-3 py-2 text-center d-none">
  <div class="row">
    <div class="col bg-white px-3 py-2">
      <div class="bg-primary mt-n0 shadow rounded">
        
        <a class="text-white font-weight-bold text-decoration-none cart-anchor" href="javascript:;" *ngIf="this.configService.configCatalog?.disableCheckout === '0'"
          (click)="openCart()">
          Carrinho
          <i class="feather-shopping-cart"></i>
          <div *ngIf="cartService.cartCount > 0" class="badge-item position-relative text-white">
            <span class="bg-secondary">{{cartService.cartCount}}</span>
          </div>
        </a>
      </div>
    </div>

  </div>
</div>

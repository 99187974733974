<section class="mt-menu-header section-payment-confirm pt-3">
    <div class="container position-relative ">
        <div class="payment-order-content mb-4" id="sidebar_fixed0">

            <div class="row" id="receipt-content" *ngIf="!loading">
                <div class="col-sm-6 col-12 pt-3">
                    <h5>{{merchantService.info.merchantName}}</h5>
                    <h6 class="contact_phone">{{merchantService.info.contactPhone | mask: '(00) 00000-0000'}}</h6>
                    <hr>
                    <div class="box-order-header">
                        <div [innerHTML]="order?.placed + '<br>' + order?.hour_create">
                        </div>
                        <div>
                            <small>PEDIDO</small><br>
                            <span class="h6">{{order?.order_id}}</span><br>
                        </div>
                        <div>
                            <small>TOTAL</small><br>
                            <span class="h6">{{order?.total | prettyPrice}}</span>
                        </div>
                        <div>
                            <button type="button" class="btn btn-sm btn-outline-secondary view-receipt-front small p-1" (click)="openModalDetailsOrder(modalOrderDetails, order.order_id)">VER<br> PEDIDO</button>
                        </div>
                    </div>
                    <hr>

                    <div class="row">
                        <div class="h6 col-12">Items</div>
                        <ul>
                            <li *ngFor="let item of order.order_details">
                                {{item.qty}}x {{item.item_name}}
                                <br>
                                <small>({{item.status}})</small>
                            </li>
                        </ul>
                    </div>


                </div>

                <div class="col-sm-6 col-12 area-pix">


                    <div class="row align-items-center justify-content-center __sectionPixConfirm">
                        <div class="icon icon--order-success svg add_bottom_15">
                            <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72">
                                <g fill="none" stroke="#8EC343" stroke-width="2">
                                    <circle cx="36" cy="36" r="35" style="stroke-dasharray:240px, 240px; stroke-dashoffset: 480px;"></circle>
                                    <path d="M17.417,37.778l9.93,9.909l25.444-25.393" style="stroke-dasharray:50px, 50px; stroke-dashoffset: 0px;"></path>
                                </g>
                            </svg>
                        </div>
                        <div class="col-12 text-center py-3">
                            <div class="h4 font-weight-bolder">Pedido Realizado, anote o numero do seu pedido <b>{{order?.order_id}}</b></div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-12">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-6 text-center">
                            <a routerLink="/{{slug}}/{{token}}" class="btn btn-secondary">Pedir mais produtos</a>
                        </div>
                    </div>
                </div>

                <!--<div class="col-sm-6 col-12 ">
                    <div class="d-flex progress-status border-bottom mt-2 mb-3 pb-2">
                        <div class="col text-center status-off"
                             [class.status-active]="order.status_code === 'INL' "
                             [class.status-off]="order.status_code !== 'INL'">
                            <p class="m-0 mb-1 text-center"><i class="feather-loader bg-novo p-1 rounded-circle"></i></p>
                            <span>AGUARDANDO CONFIRMAR</span>
                        </div>
                        <div class="col text-center"
                             [class.status-active]="order.status_code === 'ACT'"
                             [class.status-off]="order.status_code !== 'ACT'">
                            <p class="m-0 mb-1"><i class="feather-clock bg-preparo p-1 rounded-circle"></i></p>
                            <span>EM PREPARO</span>
                        </div>
                        <div class="col text-center "
                             [class.status-active]="order.status_code === 'PRT'"
                             [class.status-off]="order.status_code !== 'PRT'">
                            <p class="m-0 mb-1"><i class="feather-check-circle bg-pronto p-1 rounded-circle"></i></p>
                            <span>PEDIDO PRONTO</span>
                        </div>
                    </div>
                    <h5 *ngIf="order.status_code === 'PRT'" class="text-center font-weight-bold">
                        Pedido pronto, retire no balcão
                    </h5>

                    <div class="row justify-content-center align-items-center">
                        <div class="col-6 text-center">
                            <a routerLink="/{{slug}}/{{token}}" class="btn btn-secondary">Pedir mais produtos</a>
                        </div>
                    </div>
                </div>-->
            </div>




        </div>

    </div>
</section>
<ng-template #modalOrderDetails let-modal>
    <app-order-view></app-order-view>
</ng-template>

import { Component, OnInit } from '@angular/core';
import {FunctionsService} from '../services/functions.service';
import {Merchant} from '../class/merchant';
import {Page} from '../class/page';
import {Observable} from 'rxjs';
import {ApiResponse} from '../class/apiResponse';
import {ApiService} from '../services/api.service';
import {LoginService} from '../services/login.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  merchant: Merchant;

  showPageGeneric: boolean;
  pagesGeneric: Page[];
  pagesGeneric$: Observable<ApiResponse>;
  service$: Observable<ApiResponse>;

  showPage: boolean;
  pages: Page[];
  pages$: Observable<ApiResponse>;

  login$: Observable<string>;

  isLogin: boolean;

  date: any;

  cardList: any;


  constructor(
      public functions: FunctionsService,
      public service: ApiService)
  {
    this.date = new Date().getFullYear();
    this.showPage = false;
    this.showPageGeneric = false;
    this.pages = [];
    this.pagesGeneric = [];
  }

  ngOnInit(): void
  {
    this.isLogin = this.functions.isLogin();
    this.getMerchantInfo();
    this.getPages();
    this.getPagesGeneric();
    this.loadPaymentCardList();
    this.login$ = LoginService.loginEmitter;
    this.login$.subscribe(data => {
      if (data === 'logout' || data === 'login')
      {
        this.isLogin = this.functions.isLogin();
      }
    });
  }

  getMerchantInfo(): void
  {
    if (this.functions.getItemLocal('merchantInfo'))
    {
      this.merchant = JSON.parse(this.functions.getItemLocal('merchantInfo'));
      this.merchant.paymentList = Object.entries(this.merchant.paymentList);
    }
  }



  getPages(): void
  {
    this.pages$ = this.service.get('getPages');
    this.pages$.subscribe(data => {
      if (data.code === 1)
      {
        this.pages = data.details.data;
      }
      if (this.pages.length > 0)
      {
        this.showPage = true;
      }
    });
  }

  getPagesGeneric(): void
  {
    this.pagesGeneric$ = this.service.get('getPagesGenericAll');
    this.pagesGeneric$.subscribe(data => {
      if (data.code === 1)
      {
        this.pagesGeneric = data.details.data;
      }
      if (this.pagesGeneric.length > 0)
      {
        this.showPageGeneric = true;
      }
    });
  }

  loadPaymentCardList(): void
  {
    this.service$ = this.service.get('getPayondeliverycards');
    this.service$.subscribe(data => {
      if (data.code === 1)
      {
        this.cardList = data.details.data;
      }
    });
  }
}

<!--Scroll to top-->
<div class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}">
    <button class="btn btn-secondary px-2 py-1 align-items-center d-flex" pageScroll pageScrollOffset="0"
        href="#__topHome">
        <p class="m-0">Voltar para o topo</p> <i class="feather-arrow-up h5 m-0"></i>
    </button>

</div>
<div class="button-bottom-top text-center d-none">
    <button class="btn btn-outline-secondary small" pageScroll pageScrollOffset="0" href="#mobile-header">
        <p>Voltar para o topo</p>
    </button>
</div>
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Item} from '../class/item';

import {ApiService} from '../services/api.service';
import {ApiResponse} from '../class/apiResponse';
import {Observable} from 'rxjs';

import {FunctionsService} from '../services/functions.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MenuService} from './menu.service';
import {CartService} from '../services/new/cart.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, OnDestroy {


  itemId: string;
  catId: string;
  row: string;
  item: Item;
  item$: Observable<ApiResponse>;

  qtyMin: any;
  qtyMax: any;


  check: any[];
  tmp: any;
  apiResponse$: Observable<ApiResponse>;
  priceTotal: any;
  priceRadio: any;
  qty = 1;

  cartData: any;
  addonQty: any;
  subItems: any;

  combo: any;

  txtBtn = 'ADICIONAR';

  clickCancel: boolean;
  notes: string;
  loading: boolean;

  apiService$: Observable<ApiResponse>;
  configCatalog: any;
  service$: Observable<ApiResponse>;
  

  constructor(
      private route: ActivatedRoute,
      private service: ApiService,
      private router: Router,
      public functions: FunctionsService,
      public activeModal: NgbActiveModal,
      public menuService: MenuService,
      private cartService: CartService
  )
  {
    this.clickCancel = false;
    this.qtyMin = {};
    this.qtyMax = {};
    this.menuService.itemSelect = {};
    this.menuService.qtyItemSelect = {};

    /*NEW CODE*/
    this.catId = this.menuService.catId;
    this.itemId = this.menuService.itemId;
    this.row = this.menuService.row;
  }

  ngOnInit(): void {
    this.loading = true;
    this.service$ = this.service.getConfigCatalog();
    this.service$.subscribe(data => {
      if (data.code === 1)
      {
        this.configCatalog = data.details;
        console.log(this.configCatalog);
      }
    }, error => {
      console.log(error);
    });
    this.getMenuList();
    this.check = [];

  }

  ngOnDestroy(): void
  {
    this.menuService.row = '';
  }

  verifyCheckPrice(a): boolean
  {
    const priceSplit = a.split('|');
    if (isNaN(a))
    {
      return a.toString() === this.priceRadio.toString();
    }
    else
    {
      return Number(a).toFixed(2).toString() === Number(this.priceRadio).toFixed(2).toString();
    }
  }

  verifyHaveSizeNamePrice(price: any): boolean
  {
    return price.priceName !== '' && Number(price.sizeId) > 0;
  }

  getMenuList(): void
  {
    this.priceRadio = '';
    if (this.row !== '')
    {
      this.txtBtn = 'ALTERAR';

      this.item$ = this.service.getMenu(this.catId, this.itemId, this.row);
      this.item$.subscribe(data => {
        console.log(data);
        this.item = data.details.data;
        this.qtyMin = this.item.addon_min;
        this.qtyMax = this.item.addon_max;
        this.functions.setItemLocal('itemName', this.item.item_name);
        this.combo = this.item.addon_item_combo;
        this.verifyCombo();
        this.cartData = data.details.cart_data;
        this.addonQty = this.cartData.addon_qty;
        this.subItems = this.cartData.sub_item;

        if (this.cartData.priceNew)
        {
          if (this.cartData.priceNew.priceName)
          {
            this.priceRadio = `${this.cartData.priceNew.value}|${this.cartData.priceNew.priceName}|${this.cartData.priceNew.sizeId}|${this.cartData.priceNew.discount}`;
          }
          else
          {
            this.priceRadio = Number(this.cartData.isDiscount) ? this.cartData.priceNew.discount : this.cartData.priceNew.value;
          }
        }
        else
        {
          if (this.cartData.price)
          {
            /*console.log('cartData existe pric');*/
            this.priceRadio = this.cartData.price;

            if (this.cartData.discount)
            {
              this.priceRadio = (Number(this.priceRadio) - Number(this.cartData.discount)).toFixed(2);
            }
          }
          else
          {
            this.priceRadio = '0';
          }
        }


        /*console.log(this.priceRadio);*/
        this.qty = this.cartData.qty;

        /*VERIFICAÇÃO PARA OS ADICIONAIS QUE JA FORAM SELECIONADO PARA O ITEM*/
        /*FOR PERCORRENDO A LISTA DE ITEM DO MERCHANT*/
        for (const [k, v] of Object.entries(this.item.addon_item))
        {
          if (this.subItems && this.subItems[v.subcat_id])
          {
            /*NEW CODE*/
            if (!this.menuService.itemSelect[v.subcat_id])
            {
              this.menuService.itemSelect[v.subcat_id] = [];
              this.menuService.qtyItemSelect[v.subcat_id] = [];
            }


            for (const [l, m] of Object.entries(this.subItems[v.subcat_id]))
            {
              for (const [i, p] of Object.entries(v.sub_item))
              {
                // @ts-ignore
                const splited = m.split('|')[0];
                if (splited === p.sub_item_id)
                {
                  /*NEW CODE*/
                  this.menuService.itemSelect[v.subcat_id].push(m);
                  if (this.addonQty[v.subcat_id])
                  {
                    this.menuService.qtyItemSelect[v.subcat_id].push(this.addonQty[v.subcat_id][l]);
                  }
                }
              }
            }
          }
        }
        this.notes = this.cartData.notes;
        this.calcTotal();
        /*console.log(this.menuService.itemSelect);*/
      }, error => {
        this.functions.log(error);
      }, () => {
        this.verifyMinMaxAndCalculateType();
        this.loading = false;
      });

    }
    else
    {
      this.item$ = this.service.getMenu(this.catId, this.itemId);
      this.item$.subscribe(data => {
        /*this.functions.log(data);*/
        this.item = data.details.data;
        this.qtyMin = this.item.addon_min;
        this.qtyMax = this.item.addon_max;
        this.combo = this.item.addon_item_combo;
        this.verifyCombo();
        // tslint:disable-next-line:max-line-length
        /*NEW CODE*/
        let price: any = {};

        if (this.item?.prices_new && this.item.prices_new.length >= 1)
        {
          price = this.item.prices_new[0];

          if (price.isDiscount && price.isDiscount > 0)
          {
            this.priceRadio = price.discount;
          }
          else
          {
            this.priceRadio = price.value;
          }

          if (price.priceName !== '' && Number(price.sizeId) > 0)
          {
            this.priceRadio = price.value + '|';
            this.priceRadio += price.priceName + '|';
            this.priceRadio += price.sizeId + '|';
            this.priceRadio += price.discount;
          }

        }
        else if (this.item.prices)
        {
          if (this.item.prices.length > 1)
          {
            price = this.item.prices[0];
            this.priceRadio = (price.discount_price > 0 ? price.discount_price : price.price) + '|';
            this.priceRadio += price.size + '|';
            this.priceRadio += price.size_id;
          }
          else
          {
            this.priceRadio = this.item.prices[0].discount_price ? this.item.prices[0].discount_price : this.item.prices[0].price;
          }
        }
        else
        {
          this.priceRadio = 0;
        }

        this.calcTotal();
      }, error => {

      }, () => {
        this.verifyMinMaxAndCalculateType();
        this.loading = false;
      });
    }
  }

  verifySelect(catId: string, name: string): boolean
  {
    let find = false;
    if (this.menuService.itemSelect[catId])
    {
      for (const [c, item] of Object.entries(this.menuService.itemSelect[catId]))
      {
        const nameItem = item.toString().split('|')[2];
        if (name === nameItem)
        {
          find = true;
          break;
        }
      }
    }
    return find;
  }


  getQtySubItemValue(catId: string, name: string): string
  {
    let qty = '';
    if (this.menuService.itemSelect[catId])
    {
      for (const [c, item] of Object.entries(this.menuService.itemSelect[catId]))
      {
        const nameItem = item.toString().split('|')[2];
        if (name === nameItem)
        {
          qty = this.menuService.qtyItemSelect[catId][c];
          break;
        }
      }
    }
    return qty;
  }

  verifyCombo(): void
  {
    /*VERIFIÇÃO DOS ADICIONAIS QUE FAZEM PARTE DO COMBO*/
    const addonItem = this.item.addon_item;
    for (const [k, v] of Object.entries(addonItem))
    {
      if (this.combo)
      {
        for (const [l, m] of Object.entries(this.combo))
        {
          if (v.subcat_id === l)
          {
            for (const [n, o] of Object.entries(v.sub_item))
            {
              for (const [p, q] of Object.entries(m))
              {
                if (q === o.sub_item_id)
                {
                  if (this.item.addon_item[k].sub_item[n].price)
                  {
                    this.item.addon_item[k].sub_item[n].pretty_price = '';
                    this.item.addon_item[k].sub_item[n].price = '';
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  verifyMinMaxAndCalculateType(): void
  {
    for (const [k, v] of Object.entries(this.item.addon_max))
    {
      for (const [c, r] of Object.entries(this.item.addon_item))
      {
        if (r.subcat_id === k)
        {
          this.item.addon_item[c].max = v[0];
        }
      }
    }

    for (const [k, v] of Object.entries(this.item.addon_min))
    {
      for (const [c, r] of Object.entries(this.item.addon_item))
      {
        if (r.subcat_id === k)
        {
          this.item.addon_item[c].min = v[0];
        }
      }
    }

    if (typeof this.item.calculate_type === 'object')
    {
      for (const [k, v] of Object.entries(this.item.calculate_type))
      {
        for (const [c, r] of Object.entries(this.item.addon_item))
        {
          if (r.subcat_id === k)
          {
            this.item.addon_item[c].calculate_type = v[0];
          }
          else
          {
            this.item.addon_item[c].calculate_type = '';
          }
        }
      }
    }
    else
    {
      for (const [c, r] of Object.entries(this.item.addon_item))
      {
        this.item.addon_item[c].calculate_type = '';
      }
    }
  }


  getAddonCheck(
      e: any,
      addonId: string,
      value: string): void
  {
    if (!this.menuService.itemSelect[addonId])
    {
      this.menuService.itemSelect[addonId] = [];
      this.menuService.qtyItemSelect[addonId] = [];
    }

    if (!this.menuService.qtyItemSelect[addonId])
    {
      this.menuService.qtyItemSelect[addonId] = [];
    }


    let element;
    const idAddonItem = value.split('|')[0];

    if (e.target.checked)
    {
      /*VERIFICANDO LIMITE MAXIMO*/
      if (this.qtyMax[addonId] && this.qtyMax[addonId][0])
      {
        if (e.target.type !== 'radio')
        {
          if (this.totalAddonQty(addonId) === Number(this.qtyMax[addonId][0]))
          {
            /*console.log(this.totalAddonQty(addonId));*/
            e.target.checked = false;
            this.functions.showAlertError(`Limite máximo para esse adicional é ${this.qtyMax[addonId][0]}`);
            return;
          }
        }

      }

      /*SHOW QTD DE CADA ELEMENTO*/
      if (e.target.attributes.datatype.nodeValue === 'multiple')
      {
        element = document.querySelector(`#${e.target.id}+label span+span`);
        element.style.display = 'block';
      }

      /*NEW CODE*/
      if (this.menuService.itemSelect[addonId])
      {
        /*RADIO BUTTON*/
        if (e.target.type === 'radio')
        {
          if (this.menuService.itemSelect[addonId][0])
          {
            this.menuService.itemSelect[addonId][0] = value;
            this.menuService.qtyItemSelect[addonId][0] = '1';
          }
          else
          {
            this.menuService.itemSelect[addonId].push(value);
            this.menuService.qtyItemSelect[addonId].push('1');
          }
        }
        /*CHECKBOX*/
        else
        {
          this.menuService.itemSelect[addonId].push(value);
          this.menuService.qtyItemSelect[addonId].push('1');
        }
      }

    }
    else
    {
      let contInp = 0;
      /*HIDE QTD DE CADA ELEMENTO*/
      if (e.target.attributes.datatype.nodeValue === 'multiple')
      {
        element = document.querySelector(`#${e.target.id}+label span+span`);
        let elemInput: HTMLInputElement;
        elemInput = document.querySelector(`#${e.target.id}+label span+span input`);
        element.style.display = 'none';
        contInp = Number(elemInput.value);
        elemInput.value = '1';
      }

      /*NEW CODE*/
      if (this.menuService.itemSelect[addonId])
      {
        /*console.log(idAddonItem);*/
        for (const [k, c] of Object.entries(this.menuService.itemSelect[addonId]))
        {
          const idAddonItemTmp = c.toString().split('|')[0];
          if (idAddonItemTmp === idAddonItem)
          {
            delete this.menuService.itemSelect[addonId][k];
            delete this.menuService.qtyItemSelect[addonId][k];
            break;
          }
        }

        this.menuService.itemSelect[addonId] = this.menuService.itemSelect[addonId]
            .filter((v) => {
              return v !== null;
            });

        this.menuService.qtyItemSelect[addonId] = this.menuService.qtyItemSelect[addonId]
            .filter((v) => {
              return v !== null;
            });

        /*this.functions.log('Remover Checkbox');
        this.functions.log(this.menuService.itemSelect);
        this.functions.log(this.menuService.qtyItemSelect);*/
      }
    }

    this.calcTotal();
  }

  calcAddonItems(): number
  {
    let priceTotal = 0;
    const valuesCalc = {};
    let totalHigher = 0;
    let totalAverage = 0;

    for (const [r, i] of Object.entries(this.menuService.itemSelect))
    {
      const items: any = i;
      for (const [c, item] of Object.entries(items))
      {
        const valueSplit = item.toString().split('|');
        if (valueSplit[3] !== '')
        {
          if (!valuesCalc[valueSplit[3]])
          {
            valuesCalc[valueSplit[3]] = {};
          }
          if (!Array.isArray(valuesCalc[valueSplit[3]][valueSplit[4]]))
          {
            valuesCalc[valueSplit[3]][valueSplit[4]] = [];
          }
          valuesCalc[valueSplit[3]][valueSplit[4]].push(Number(valueSplit[1]));
        }
        else
        {
          const price = valueSplit[1];
          if (price)
          {
            priceTotal += Number(price) * Number(this.menuService.qtyItemSelect[r][c]);
          }
        }

        if (valuesCalc[`higher`] != null)
        {
          for (const [index, higher] of Object.entries(valuesCalc[`higher`]))
          {
            if (Array.isArray(higher))
            {
              totalHigher = higher.reduce((a, b) => {
                return  Math.max(a, b);
              }, -Infinity);
            }
          }
        }

        if (valuesCalc[`average`] != null)
        {
          for (const [index, average] of Object.entries(valuesCalc[`average`]))
          {
            if (Array.isArray(average))
            {
              const sum = average.reduce((partialSum, a) => partialSum + a, 0);
              totalAverage = Number(sum) / Number(average.length);
            }
          }
        }
      }

      priceTotal += Number(totalHigher) + Number(totalAverage);
    }
    return priceTotal;
  }

  calcTotal(): void
  {
    let priceRadio = '';
    if (this.item.prices_new.length)
    {
      if (typeof this.priceRadio === 'string' && this.priceRadio?.split('|').length > 1)
      {
        priceRadio = this.priceRadio.split('|')[0];
        if (Number(this.priceRadio.split('|')[3]) > 0)
        {
          priceRadio = this.priceRadio.split('|')[3];
        }

      }
      else
      {
        priceRadio = this.priceRadio;
      }
    }
    else
    {
      if (typeof this.priceRadio === 'string' && this.priceRadio?.split('|').length > 1)
      {
        priceRadio = this.priceRadio.split('|')[0];
      }
      else
      {
        priceRadio = this.priceRadio;
      }
    }

    /*console.log(priceRadio);
    console.log(this.calcAddonItems());
    console.log(this.qty);*/
    this.priceTotal = (Number(priceRadio) + this.calcAddonItems()) * this.qty;
  }

  // ------------------
  // CHANGE PRICE
  selectPrice(value): void
  {
    this.priceRadio = value;
    this.calcTotal();
  }

  minusBtn(e, input, where: string = '', subCatId: string = '', subItemId: string = '', subItemPrice: any = ''): void
  {
    /*QUANTIDADE TOTAL DO PRODUTO*/
    if (where === 'total')
    {
      this.qty = Number(input.innerHTML) - 1;
      if (this.qty <= 0)
      {
        this.qty = 1;
      }
    }
    /*QUANTIDADE TOTAL DE UM ADICIONAL*/
    else
    {
      input.value = Number(input.value) - 1;
      /*VERIFICANDO ADDON QTY PARA ALTERAR O SEU VALOR*/
      /*NEW CODE*/
      if (this.menuService.itemSelect[subCatId])
      {
        for (const [k, v] of Object.entries(this.menuService.itemSelect[subCatId]))
        {
          this.menuService.qtyItemSelect[subCatId][k] = Number(this.menuService.qtyItemSelect[subCatId][k]) - 1;
          if (this.menuService.qtyItemSelect[subCatId][k] <= 1)
          {
            this.menuService.qtyItemSelect[subCatId][k] = 1;
          }
        }
      }
    }
    /*FIM ADDON QTY*/
    if (input.value <= 1)
    {
      input.value = 1;
    }
    this.calcTotal();
  }

  plusBtn(e,
          input,
          where: string = '',
          max: string = '',
          subcatName: string = '',
          subCatId: string = '',
          subItemId: string = '',
          subItemPrice: string = ''): void
  {
    if (where === 'total')
    {
      this.qty = Number(input.innerHTML) + 1;
      if (this.qty <= 0)
      {
        this.qty = 1;
      }
    }

    else
    {
      if (Number(this.qtyMax[subCatId][0]) !== 0)
      {
        if (this.totalAddonQty(subCatId) < Number(this.qtyMax[subCatId][0]))
        {
          input.value = Number(input.value) + 1;
          /*VERIFICANDO ADDON QTY PARA ALTERAR O SEU VALOR*/
          /*NEW CODE*/
          if (this.menuService.itemSelect[subCatId])
          {
            for (const [k, v] of Object.entries(this.menuService.itemSelect[subCatId]))
            {
              const id = v.toString().split('|')[0];
              if (id === subItemId)
              {
                this.menuService.qtyItemSelect[subCatId][k] = Number(this.menuService.qtyItemSelect[subCatId][k]) + 1;
              }
            }
          }
        }
        else
        {
          this.functions.showAlertError(`Você só pode selecionar ${this.qtyMax[subCatId][0]} ${subcatName}`);
          return;
        }
      }
      else
      {
        input.value = Number(input.value) + 1;
        /*VERIFICANDO ADDON QTY PARA ALTERAR O SEU VALOR*/
        /*NEW CODE*/
        if (this.menuService.itemSelect[subCatId])
        {
          for (const [k, v] of Object.entries(this.menuService.itemSelect[subCatId]))
          {
            const id = v.toString().split('|')[0];
            if (id === subItemId)
            {
              this.menuService.qtyItemSelect[subCatId][k] = Number(this.menuService.qtyItemSelect[subCatId][k]) + 1;
            }
          }
        }
      }
    }
    if (input.value <= 0)
    {
      input.value = 1;
    }
    this.calcTotal();
  }

  totalAddonQty(subCatId: string): number
  {
    let total = 0;
    /*NEW CODE*/
    if (this.menuService.itemSelect[subCatId])
    {
      for (const [k, v] of Object.entries(this.menuService.itemSelect[subCatId]))
      {
        total += Number(this.menuService.qtyItemSelect[subCatId][k]);
      }
    }

    return total;
  }


  scrollPage(element: string, offset: number = 90, body: any = ''): void
  {
    const yOffset = -offset;

    /*this.functions.log(element);*/
    const e = document.querySelector('#' + element).getBoundingClientRect().top ;
    if (body)
    {
      const div = document.querySelector(`#${element}`);

      /*this.functions.log(div);*/

      div.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
    else
    {
      window.scrollTo({top: e, behavior: 'smooth'});
    }
    /*document.querySelector(element).scrollIntoView({behavior: 'smooth'});*/
  }

  // --------------------------
  // ADD TO CART
  addToCart(form, btn: any): void
  {
    const params = [];
    const priceInp = document.querySelector('input[name="price"]:checked') as HTMLInputElement;
    if (priceInp)
    {
      if (this.item.prices_new.length)
      {
        if (priceInp.value.split('|').length > 1)
        {
          let priceNewSelect = null;
          for (const p of this.item.prices_new)
          {
            if (p.value === priceInp.value.split('|')[0])
            {
              priceNewSelect = p;
            }
          }
          form.value.price = priceNewSelect.value;
          form.value.priceNew = priceNewSelect;
          if (Number(priceInp.value.split('|')[3]) > 0)
          {
            form.value.discount = priceNewSelect.discount;
            form.value.isDiscount = true;
          }
        }
        else
        {
          form.value.price = priceInp.value;
        }
      }
      else
      {
        if (priceInp.value.split('|').length === 1 && this.item.discount)
        {
          form.value.price = this.item.prices[0].price;
        }
        else
        {
          form.value.price = priceInp.value;
        }
      }

    }
    else if (this.priceTotal === 0)
    {
      this.functions.showAlertError('É necessário selecionar complementos!');
      return;
    }

    if (form.value.price === '')
    {
      this.functions.scrollPage('price-content', 90);
      this.functions.showAlertError('Selecione o preço do produto');
      return;
    }

    const require: NodeList = document.querySelectorAll('.require_addons');
    const error = document.querySelectorAll('.error');

    /*REMOVENDO ERROS*/
    // @ts-ignore
    for (const c of error)
    {
      c.classList.remove('error');
    }
    /*END ERROR REMOVE*/

    /*INICIANDO VERIFICAÇÕES DE ADICIONAIS OBRIGATORIOS*/
    const listErrors = [];
    if (require.length > 0)
    {
      // tslint:disable-next-line:prefer-for-of
      for (let c = 0; c < require.length; c++)
      {
        const element = require[c] as HTMLInputElement;
        const subCatName = element.dataset.subcat_name;
        const subCatId = element.dataset.subcat_id;
        const title = document.querySelector(`#${subCatName}`);
        const h6 = document.querySelector(`#name${title.id}`);
        /*console.log(h6);*/
        const min = title.previousElementSibling as HTMLInputElement;

        const span = document.querySelector(`#name${title.id} br+small`);
        const contentInputs = document.querySelectorAll('#' + subCatName + '+input+.' + subCatName + ' input:checked');
        if (this.qtyMin[subCatId][0])
        {
          /*this.functions.log('verificação do min');
          this.functions.log(`Total de inputs: ${contentInputs}`);
          this.functions.log(this.qtyMin[subCatId][0]);
          this.functions.log(this.totalAddonQty(subCatId));
          this.functions.log(subCatId);*/
          // tslint:disable-next-line:radix
          if (this.totalAddonQty(subCatId) < Number(this.qtyMin[subCatId][0]))
          {
            /*this.functions.log(this.totalAddonQty(subCatId));*/
            span.classList.add('text-danger');
            h6.classList.add('error');
            listErrors.push(subCatName);
          }
          else
          {
            h6.classList.remove('error');
            span.classList.remove('text-danger');
          }
        }
        else if (contentInputs.length === 0) {
          /*  this.functions.log('verificação quantidade de check');
            this.functions.log(contentInputs);
            this.functions.log(min);
            this.functions.log(this.totalAddonQty(subCatId));
            this.functions.log(subCatName);*/

          span.classList.add('text-danger');
          h6.classList.add('error');
          listErrors.push(subCatName);
        } else {
          h6.classList.remove('error');
          span.classList.remove('text-danger');
        }
      }

      if (listErrors.length > 0)
      {
        const bodyModal = document.querySelector('#__addonList');
        this.scrollPage(`${listErrors[0]}`, 90, bodyModal);
        /*this.functions.log(listErrors);*/
        return;
      }
    }
    /*FIM DA VERIFICAÇÃO DE ADICIONAIS OBRIGATORIOS*/

    /*ADD PARAMS FORMS*/
    for (const [k, v] of Object.entries(form.value))
    {
      params.push({name: k, value: v });
    }

    params.push({name: 'sub_item', value: this.menuService.itemSelect});
    params.push({name: 'addon_qty', value: this.menuService.qtyItemSelect});
    params.push({name: 'qty', value: this.qty});
    params.push({name: 'notes', value: this.notes});

    const listCalculateType = document.querySelectorAll('.calculate_type');

    if (listCalculateType.length)
    {
      // @ts-ignore
      for (const type of listCalculateType)
      {
        const values = type.value.toString().split('_');
        params.push({name: type.value, value: this.item?.calculate_type[values[2]][0]});
      }
    }

    let msgAlert = '';

    if (this.row !== '')
    {
      params.push({name: 'row', value: this.row});
      msgAlert = 'Atualizado';
    }
    else
    {
      msgAlert = 'Adicionado';
    }
    /*console.log(params);*/

    this.clickCancel = true;

    this.apiResponse$ = this.service.addItemCart(params);

    this.apiResponse$.subscribe(
        data => {
          /*this.functions.log(data);*/
          if (data.code === 1)
          {
            this.functions.showAlertSuccess(this.item.item_name, `${msgAlert} ao carrinho`);
            this.cartService.cartCount += 1;
            if (this.functions.getItemLocal('cartCount'))
            {
              this.functions.setItemLocal('cartCount', (Number(this.functions.getItemLocal('cartCount')) + 1).toString());
            }
            else
            {
              this.functions.setItemLocal('cartCount', '1');
            }

            let addressLocal = this.functions.getItemLocal('deliveryAddress');
            if (addressLocal)
            {
              addressLocal = JSON.parse(addressLocal);
              this.apiService$ = this.service.changeAddressCart(addressLocal);
              this.apiService$.subscribe(d => {
                if (d.code === 1)
                {
                  this.cartService.loadCart();
                  this.activeModal.close();
                }
                else
                {
                  this.functions.showAlertError(d.msg);
                }
              }, e => {
                this.functions.log(e);
                this.functions.showAlertErroServer();
              });
            }
            else
            {
              this.cartService.loadCart();
              this.activeModal.close();
            }
          }
          else
          {
            this.functions.showAlertError(data.msg);
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => {
          this.functions.log(error);
          this.functions.showAlertErroServer();
        },
        () => {
          this.clickCancel = false;
          this.menuService.row = '';
        }
    );
  }


  getValueCalculateType(catId): string
  {

    if (this.item.calculate_type[catId])
    {
      return `calculate_type_${catId}`;
    }

    return '';
  }



}

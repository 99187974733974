<header class="section-header fixed-top">
    <section class="header-main shadow-sm bg-white">
        <div class="container py-lg-3">
            <div class="row align-items-center">
                <div class="col-2 d-flex align-items-center sm-none">
                    <a class="btn btn-sm btn-outline-primary small p-1" href="javascript:;" routerLink="/{{slug}}/{{this.isTable() ? this.service.getToken() : ''}}" >
                        <i class="feather-arrow-left font-weight-bold"></i>
                        <span class="text-center"> RETORNAR</span>
                    </a>
                </div>
                <div class="col-10 d-flex align-items-center sm-none">
                    <h4 class="font-weight-bold m-0">Confirmação de pedido</h4>
                </div>
                <!-- col.// -->
            </div>
            <!-- row.// -->
        </div>
        <!-- container.// -->
    </section>
    <!-- header-main .// -->
</header>

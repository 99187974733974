import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class MenuService {

  catId: string;
  itemId: string;
  row: string;

  itemSelect: any;

  qtyItemSelect: any;



  constructor()
  {
    this.catId = '';
    this.itemId = '';
    this.row = '';
    this.itemSelect = {};
    this.qtyItemSelect = {};
  }

  openModalEdit(catId, itemId, row): void
  {
    this.catId = catId;
    this.itemId = itemId;
    this.row = row;
  }

}

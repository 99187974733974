<div class="py-3 mt-menu-header" *ngIf="!service.loading" id="__topHome">
  <div class="container position-relative ">
    <div class="merchant-logo-desk">
      <img class="rounded" alt="{{merchant.merchantName}}" src="{{merchant.optionsMerchant?.logo_app}}">
    </div>
    <div class="py-3 ml-4">
      <div>
        <h2 class="font-weight-bold m-0"> {{merchant.merchantName}} </h2>
        <div class="row">
          <!--- AJUSTAR O BUG-->
          <div class="col col-sm-3 col-md-2" *ngIf="cartService.deliveryEstimation">
            <p class="font-weight-bold m-0 small">TEMPO MÉDIO</p>
            <p class="text-muted m-0">{{cartService.deliveryEstimation}}</p>
          </div>

          <div class="col col-sm-3 col-md-2" *ngIf="!loadingHours">
            <!-- HORÁRIO QUANDO FECHADO  -->
            <p *ngIf="!open" class="m-0"><span class="badge badge-warning small">FECHADO AGORA</span></p>
            <p *ngIf="!open" class="m-0 small">{{closeText}}</p>
            <p *ngIf="!open" class="m-0 ">{{closeHour}}</p>
            <!-- HORÁRIO QUANDO ABERTO -->
            <p *ngIf="open" class="m-0"><span class="badge badge-success small">ABERTO HOJE</span></p>
            <p *ngIf="open" class="text-muted m-0">{{hoursToday}}</p>
          </div>
        </div>
        <div class="row p-1 my-2 rounded position-relative">
          <a href="javascript:" (click)="openModalInfoMerchant()"
            class="btn info-anchor bg-primary col-2 btn-sm btn-outline-light">INFO</a>
        </div>
      </div>
      <div class="cest-container" *ngIf="this.configService.configCatalog?.disableCheckout === '0'">
        <div class="row p-1 rounded position-relative">
          <a href="javascript:" (click)="openModalDetailsOrder(modalOrderDetails, orderId)" class="widget-header btn btn-primary rounded text-dark">
            <div class="icon d-flex align-items-center p-1">
              <!--<span *ngIf="cartService.cartCount > 0" class="badge-item-header position-absolute ml-2 text-white">
                <span
                      class="small">{{cartService.cartCount}}
                </span>
              </span>-->
              <span class="pl-2">Comanda</span>
            </div>
          </a>
        </div>
        <div class="row p-1 rounded position-relative" >
          <a href="javascript:" (click)="openModalCart()" class="widget-header btn btn-primary rounded text-dark">
            <div class="icon d-flex align-items-center p-1">
              <i class="feather-shopping-bag h6 mr-2 mb-0"></i>
              <span *ngIf="cartService.cartCount > 0" class="badge-item-header position-absolute ml-2 text-white"><span
                  class="small">{{cartService.cartCount}}</span></span>
              <span class="pl-2">Cesta</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalOrderDetails let-modal>
  <app-order-view></app-order-view>
</ng-template>

<div class="container" *ngIf="!service.loading">
  <section id="mobile-header" class="hide">
    <div class="header-mobile-info rounded py-4">
      <div class="image-header-mobile">
        <a href="javascript:" routerLink="/home">
          <img alt="{{merchant.merchantName}}" src="{{merchant.optionsMerchant?.logo_app}}">
        </a>
      </div>
      <div class="info-mobile-header">
        <h3 class="mb-0 font-weight-bold">{{merchant.merchantName}}</h3>
        <div class="row p-1 rounded position-relative">
          <a href="javascript:"
            class="btn info-anchor bg-primary col-2 btn-sm btn-outline-light">INFO</a>
        </div>
      </div>
    </div>
  </section>
</div>

<nav *ngIf="!service.loading" #tagMenu id="tagMenu" class=" py-2 pt-sm-2 pb-sm-0 cat-slider-menu" [class.container]="!isSticky"
  [class.tag-menu]="isSticky">
  <ul class="mb-0 container" id="category_header">
    <li *ngFor="let cat of category | keyvalue: asIsOrder; let i = index"
      [ngClass]="{ 'active': currentSection === '_'+cat.value.cat_id}"
      class="btn btn-sm btn-outline-primary btn-link m-1 zindex {{'_'+cat.value.cat_id}}">
      <a pageScroll pageScrollOffset="100" href="{{'#_'+cat.value.cat_id}}">
        {{cat.value.category_name}}
      </a>
    </li>
  </ul>
</nav>
<div class="container position-relative container-menu-item">

  <div class="row">
    <div class="col pt-3">
      <div
              class="row mb-3 __categories"
              *ngFor="let cat of category | keyvalue: asIsOrder; let i = index"
              id="{{'_'+cat.value.cat_id}}"
              appScrollSpy [spiedTags]="['DIV']"
              (sectionChange)="onSectionChange('_'+cat.value.cat_id)"
      >
        <h5 #titleCat class="p-3 w-100 menu-categories-title bg-light border-top">
          <b>{{cat.value.category_name}}</b><br>
          <!--- <small class="text-black-30"><span class="small"> {{cat.value.count}} OPÇÕES</span></small> -->
          <small class="text-muted" *ngIf="cat.value.category_description">{{cat.value.category_description}}</small>
        </h5>

        <div class="col-lg-6 col-md-12 col-sm-6 col-12 mb-3"  *ngFor="let item of cat.value.items | keyvalue: asIsOrder; let i = index">
          <div class="d-flex list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
            <div class="list-card-image">
              <div class="member-plan position-absolute">
                <span class="badge badge-success" [style.display]="item.value.prices && item.value.prices[0].discount_price > 0 ? 'block' : 'none'">PROMOÇÃO</span>
              </div>
              <a href="javascript:;" (click)="openEdititem(cat.value.cat_id, item.value.item_id)">
                <img [defaultImage]="'/assets/images/default-food-image.png'" [lazyLoad]="item.value.photo" src="{{item.value.photo}}" class="img-fluid item-img w-100">
              </a>
            </div>

            <div class="p-2 p-md-3 position-relative w-100">
              <div class="list-card-body">
                <a href="javascript:;" (click)="openEdititem(cat.value.cat_id, item.value.item_id)">
                  <h6 class="mb-0 mb-sm-1 text-black">{{item.value.item_name}}</h6>
                  <p class="text-gray text-muted mb-1 font-weight-light">{{item.value.item_description}}</p>
                  <p class="text-muted mb-0 d-flex list-card-price font-weight-bold">
                          <span class="normal-price text-muted mr-2 font-weight-light" *ngIf="item.value.prices && item.value.prices[0].discount_price > 0 " >
                            {{item.value.prices && item.value.prices[0].formatted_price ? item.value.prices[0].formatted_price : '' }}
                          </span>
                    <span *ngIf="item.value.pricesNew; else oldPriceDiv">
                              <small *ngIf="item.value.pricesNew.priceName">{{item.value.pricesNew.priceName}}</small>
                              <span class="normal-price d-block" *ngIf="item.value.pricesNew.isDiscount && item.value.pricesNew.isDiscount > 0">{{item.value.pricesNew.value | prettyPrice}}</span>
                              <span *ngIf="item.value.pricesNew.isDiscount && item.value.pricesNew.isDiscount > 0">{{item.value.pricesNew.discount | prettyPrice}} </span>

                              <span *ngIf="item.value.pricesNew.isDiscount === '0'">{{item.value.pricesNew.value | prettyPrice}}</span>

                          </span>
                    <ng-template #oldPriceDiv>
                            <span *ngIf="item.value.prices">
                              <small>
                                {{item.value.prices && item.value.prices[0].size ? item.value.prices[0].size : '' }}
                              </small>

                              <span class="normal-price d-block" *ngIf="item.value.prices[0].discount_price && item.value.prices[0].discount_price > 0">
                                {{item.value.prices[0].formatted_price | prettyPrice}}
                              </span>
                              <span *ngIf="item.value.prices[0].discount_price && item.value.prices[0].discount_price > 0">
                                {{item.value.prices[0].discount_price | prettyPrice}}
                              </span>

                              <span *ngIf="!item.value.prices[0].discount_price">{{item.value.prices[0].formatted_price}}</span>

                              <!--{{item.value.prices ? item.value.prices[0].discount_price ? item.value.prices[0].formatted_discount_price : item.value.prices[0].formatted_price : ''}}-->
                          </span>
                    </ng-template>

                    <span *ngIf="!item.value.pricesNew && !item.value.prices.length" class="text-success small font-weight-bold">VEJA AS OPÇÕES</span>

                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="col-md-4 pt-3" *ngIf="this.configService.configCatalog?.disableCheckout === '0'">
      <app-cart-lateral></app-cart-lateral>
    </div>

  </div>

</div>
<app-scroll-to-top></app-scroll-to-top>
<app-footer-bar></app-footer-bar>

<!-- MODAL  -->

<ng-template #modalInfoMerchant let-modalInfo>
  <!-- MODAL HEADER -->
  <div class="modal-header-mechant">
    <div class="logo-image-merchant rounded mr-2 brand-wrap ">
      <img src="{{merchant.optionsMerchant.logo_app}}">
    </div>
    <h4 id="exampleModalLabel">{{merchant.merchantName}}</h4>
    <button type="button" class="close" (click)="modalInfo.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true"><i class="feather-x"></i></span>
    </button>
  </div>

  <div class="modal-body bg-light pb-4 p-3">

    <div class="mb-3 px-2">
      <div class="">
        <h6 class="font-weight-light text-dark my-2"> <i class="feather-map-pin icon-color-01 mr-1"></i>
          {{merchant.street}}, {{merchant.number}}<br> <small>{{merchant.bairro}} - {{merchant.city}} -
            {{merchant.state}} {{merchant.postCode}}</small></h6>
        <hr>
        <h6 class="font-weight-light text-dark my-2"><i class="feather-message-circle icon-color-01 mr-1"></i>
          <a [href]="whatsapp"> {{merchant.contactPhone | mask: '(00) 00000-0000'}}
            <span *ngIf="merchant.whatsapp === '1'" class="font-weight-light badge badge-success small">
              <small>CLIQUE PARA CHAMAR</small><br><i class="feather-message-circle mr-1"></i>WHATS APP
            </span>
          </a>
        </h6>
        <hr>
      </div>

      <div class="row">
        <div class="col">
          <p class="font-weight-bold m-0">Entrega </p>
          <p class="text-muted m-0">{{deliveryCharge ? functions.prettyPrice(deliveryCharge) : ''}}</p>
          <small *ngIf="!deliveryCharge">É preciso informar um endereço</small>
        </div>
        <div class="col">
          <!-- HORÁRIO QUANDO FECHADO  -->
          <p *ngIf="!open" class="m-0"><span class="badge badge-warning small">FECHADO AGORA</span></p>
          <p *ngIf="!open" class="m-0 small">{{closeText}}</p>
          <p *ngIf="!open" class="m-0 ">{{closeHour}}</p>
          <!-- HORÁRIO QUANDO ABERTO -->
          <p *ngIf="open" class="font-weight-bold m-0">Aberto hoje</p>
          <p *ngIf="open" class="text-muted m-0">{{hoursToday}}</p>
        </div>
      </div>
    </div>

    <div>
      <div class="box shadow-sm rounded bg-white mb-3">
        <div class="p-3 d-flex align-items-center">
          <i class="feather-message-circle display-4 icon-color-03"></i>
          <div class="ml-4">
            <h6 class="text-dark mb-2 mt-0">Informações</h6>
            <p class="mb-0 text-muted">{{merchant.information}}</p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="box shadow-sm rounded bg-white mb-3">
        <div class="p-3 d-flex align-items-center">
          <i class="feather-dollar-sign display-3 icon-color-03"></i>
          <div class="ml-4">
            <h6 class="text-dark mb-2 mt-0">Opções de pagamento</h6>
            <p class="mb-1 mt-3" *ngFor="let payment of merchant.paymentList | keyvalue">
              <i class="feather-chevrons-right icon-color-02"></i>
              {{payment.value}}
              <!--EXIBIÇÃO DAS BANDEIRAS DE CARTÃO-->
              <span *ngIf="payment.key === 'pyr'">
                <br>
                <span *ngFor="let card of cardList">
                  &nbsp;
                  <img src="{{card.payment_logo}}" alt="{{card.payment_name}}" class="img-fluid" width="25">
                </span>
              </span>
              <!--FIM DAS BANDEIRAS-->
            </p>

          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="box shadow-sm rounded bg-white mb-3">
        <div class="p-3 d-flex align-items-center">
          <i class="feather-clock display-4 icon-color-03"></i>
          <div class="ml-4">
            <h6 class="text-dark mb-2 mt-0">Horário de Funcionamento</h6>
            <p class="mb-1" *ngFor="let open of merchant.openingHours">{{open.day}} <span
                class="float-right text-dark">{{open.hours}}</span></p>
          </div>
        </div>
      </div>
    </div>
    <h6 class="text-dark m-2">Onde estamos</h6>

    <app-maps [location]="locationMerchant"></app-maps>

    <div class="my-3 bg-white rounded shadow">
      <a class="d-flex w-100 align-items-center px-3 py-4" *ngFor="let page of pagesGeneric"
        (click)="showPageCustom(page.identify)" href="javascript:;">
        <i class="{{page.icon}} text-white p-2 rounded-circle mr-2"></i>
        <div class="left mr-3">
          <h6 class="font-weight-bold m-0 text-dark">{{page.title}}</h6>
        </div>
        <div class="right ml-auto">
          <h6 class="font-weight-bold m-0"><i class="feather-chevron-right"></i></h6>
        </div>
      </a>
    </div>

  </div>

  <div class="modal-footer border-top p-0 ">
    <div class="col-12 m-1 p-0">
      <button type="button" class="btn btn-block btn-lg" (click)="modalInfo.dismiss('Cross click')"> <i
          class="feather-x-circle"></i> Fechar</button>
    </div>
  </div>

</ng-template>

<ng-template #modalRoute let-modalRoute>
  <div class="modal-header bg-primary border-bottom p-3 text-white">
    <p class="m-0 text-white pr-3">Rota até <span class="h6 text-white txt-nowrap">{{merchant.merchantName}}</span> <br>
      <small> <span class="small">DISTÂNCIA</span> {{distanceInfo.pretty_distance}}</small>
    </p>
    <!---
      <button type="button" class="close" (click)="modalPass.dismiss('Cross click')" aria-label="Close"></button>
      -->
  </div>
  <div class="p-3 pt-2" *ngIf="deliveryAddress">
    <p class="m-0 txt-nowrap"><i class="feather-home"></i> {{deliveryAddress.street}}, {{deliveryAddress.number}} -
      {{deliveryAddress.bairro}} - {{deliveryAddress.city}} - {{deliveryAddress.state}}</p>
    <p class="m-0"><i class="feather-more-vertical"></i></p>
    <p class="m-0 txt-nowrap"><i class="feather-map-pin"></i> {{merchant.street}}, {{merchant.number}} -
      {{merchant.bairro}} - {{merchant.city}} - {{merchant.state}}</p>
  </div>

  <div id="map2"></div>

  <div class="modal-footer border-top p-0 ">
    <button type="button" class="btn btn-block btn-lg" (click)="modalRoute.dismiss('Cross click')"> <i
        class="feather-x-circle"></i> Fechar</button>
  </div>
</ng-template>


<!--<ng-template [ngIf]="!category?.length">
  <app-spinner></app-spinner>
</ng-template>-->

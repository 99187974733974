<div class="cart-lateral-01 mb-3 rounded rounded shadow-sm overflow-hidden bg-white sticky_sidebar"
    *ngIf="!cartService.loading">
    <div class="d-flex align-items-center border-bottom bg-white p-3 cart-lateral-img" *ngIf="merchantService.info">
        <img alt="{{merchantService.info.optionsMerchant.logo_app}}" src="{{merchantService.info.optionsMerchant.logo_app}}"
            class="mr-3" width="50">
        <div class="d-flex flex-column">
            <h6 class="mb-1 font-weight-bold">{{merchantService.info.merchantName}}</h6>
        </div>
    </div>

    <div class="bg-white py-2 cart-itens" *ngIf="!cartService.cartEmpty">
        <div *ngFor="let item of cartService.cartData.item | keyvalue; let i = index" class="border-bottom pb-2">
            <div class="d-flex list-card bg-white overflow-hidden position-relative px-3 pt-2 pt-md-3 mb-1">
                <div class="media menu-list-checkout media-cart">
                    <img [src]="item.value.photo" [alt]="item.value.item_name" width="50" class="mr-2 rounded ">
                    <div class="media-body">
                        <p class="mb-1 cart-item-name">{{item.value.qty}} X {{item.value.item_name}} </p>
                        <p class="price-card price-position text-black mb-0">

                            <small *ngIf="item.value.qty > 1" class="text-muted">
                                {{item.value.qty}} X <small>{{item.value.size_words}}</small>
                                {{cartService.getNormalPrice(item.key, item.value.qty, true) | prettyPrice}} =
                            </small>

                            <span class="font-weight-bold">
                                <small *ngIf="item.value.qty === 1">{{item.value.size_words}}</small>
                                {{cartService.getNormalPrice(item.key, item.value.qty) | prettyPrice}}
                            </span>

                        </p>
                    </div>
                </div>
                <div class="btn-add ml-auto ">
                    <a class="btn p-0 text-danger" href="javascript:;" (click)="cartService.removeCartItem(item.key)"><i
                            class="feather-x h6 font-weight-bold"></i></a>
                    <a class="btn p-0" href="javascript:;"
                        (click)="modalService.openEditItemModal(item.value.category_id, item.value.item_id, item.key)">
                        <i class="feather-edit h6 font-weight-bold"></i></a>
                </div>
            </div>
            <div class="d-block px-3 text-muted">
                <small [innerHTML]="cartService.subItemHtml[i]"></small>
            </div>
        </div>
    </div>

    <!--VERIFICAÇÃO CASO NAO HAJA ITEM NO CARRINHO-->
    <div *ngIf="cartService.cartEmpty" class="justify-content-center align-items-center text-center mt-14 py-5">
        <h5>{{'Sua cesta está vazia'}}</h5>
    </div>

    <div class="bg-white px-3 pt-2 clearfix" *ngIf="cartService.cartData.total.total">
        <p class="mb-1">SubTotal <span
                class="float-right text-dark">{{functions.prettyPrice(cartService.cartData.total.subtotal)}}</span></p>
        <!--- <h6 class="font-weight-bold mb-0">TOTAL A PAGAR <span class="float-right">{{transactionType === 'delivery' ? functions.prettyPrice(cartData.total.total) : functions.prettyPrice(cartData.total.subtotal)}}</span></h6>--->
    </div>

    <div class="px-3 pt-2 pb-3" *ngIf="cartService.cartData.total.total">
        <a *ngIf="functions.isOpen()" class="btn btn-success btn-block" href="javascript:;"
            (click)="checkout()">{{txtBtn}} {{functions.prettyPrice(cartService.cartData.total.total)}} <i
                class="feather-arrow-right"></i></a>
        <p *ngIf="!functions.isOpen()" class="m-0 text-center"><span class="badge badge-warning small">FECHADO
                AGORA</span></p>
    </div>

    <div class="text-center pb-3" *ngIf="cartService.cartData.total.total">
        <button type="button" class="btn btn-outline-secondary small" (click)="cartService.cleanCart()"> <i
                class="feather-x"></i> ESVAZIAR
            CESTA</button>
    </div>

</div>

<app-loading *ngIf="cartService.loading"></app-loading>

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Merchant} from '../../class/merchant';
import {FunctionsService} from '../../services/functions.service';

import {Observable} from 'rxjs';
import {ApiResponse} from '../../class/apiResponse';
import {Address} from '../../class/address';
import {UserProfile} from '../../class/user';
import {CartData, CartDetails, Details} from '../../class/cart';
import {CartService} from '../../services/new/cart.service';

import {AddressService} from '../../services/address.service';
import {CheckoutService} from '../../services/checkout.service';
import {ModalService} from '../../services/new/modal.service';
import {MerchantService} from '../../services/merchant.service';

@Component({
  selector: 'app-cart-lateral',
  templateUrl: './cart-lateral.component.html',
  styleUrls: ['./cart-lateral.component.css']
})
export class CartLateralComponent implements OnInit {

  merchantInfo: Merchant;
  cart$: Observable<ApiResponse>;

  addressService$: Observable<boolean>;



  address: Address;
  userProfile: UserProfile;
  addressList: Address[];
  merchant: Merchant;
  details: Details;
  cartDetails: CartDetails;
  cartData: CartData;
  photos: any[];
  transactionType = '';
  subItemHtml: any[];

  transactionUrl: string;

  addressDelivery: any;
  showAddress = false;

  open: any;

  visit: boolean;
  cartEmpty: boolean;

  step: any;
  txtBtn: string;

  payment: boolean;

  loading: boolean;


  constructor(
      public functions: FunctionsService,
      public checkoutService: CheckoutService,
      public cartService: CartService,
      public modalService: ModalService,
      public merchantService: MerchantService
  )
  {
    this.loading = false;
    this.payment = false;
    this.subItemHtml = [];

    /*console.log(this.merchantInfo);*/
    this.cartData = new CartData();
    this.cartDetails = new CartDetails();
    this.step = this.functions.getItemLocal('step');
  }

  ngOnInit(): void
  {
    if (this.functions.getItemLocal('transaction_type'))
    {
      this.transactionType = this.functions.getItemLocal('transaction_type');
    }

    this.addressService$ = AddressService.addressEmitter;
    this.addressService$.subscribe(data => {
      if (this.cartService.cartCount > 0)
      {
        /*console.log('loading cart ');*/
        this.cartService.loadCart();
      }
    });

    this.txtBtn = 'Confirmar Pedido';
  }

  transactionTypeChange(e): void
  {
    console.log(e);
    const type = e.target.value;
    console.log(type);
    this.transactionType = type;
    this.showAddress = false;
    if (type === 'delivery')
    {
      if (this.functions.getItemLocal('deliveryAddress'))
      {
        this.addressDelivery = JSON.parse(this.functions.getItemLocal('deliveryAddress'));
        this.showAddress = true;
      }
      else
      {
        this.functions.showAlertError('Houve um problema com seu endereço!');
      }
    }

    this.functions.setItemLocal('transaction_type', type);

    this.cartService.loadCart();

  }


  checkout(): void
  {
    if (this.functions.getItemLocal('open') && this.functions.getItemLocal('open') === '0')
    {
      this.functions.showAlertError('Estabelecimento Fechado', 'Não é possivel realizar pedidos');
      return;
    }

    /*console.log(this.merchantInfo.minimumOrder);*/

    if (this.merchantInfo?.minimumOrder)
    {
      if (Number(this.cartService.cartData.total.subtotal) < Number(this.merchantInfo.minimumOrder))
      {
        this.functions.showAlertError('Limite mínimo para pedidos', `O pedido deve ser maior que ${this.functions.prettyPrice(this.merchantInfo.minimumOrder)}`);
        return;
      }
    }


    else if (this.step && this.step === 'payment')
    {
      this.functions.log('Payment');
      this.functions.log('Finalizar a Venda');
    }

    else
    {
      this.functions.redirect('payment');
      this.functions.setItemLocal('step', 'payment');
    }
  }





}

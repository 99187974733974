import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResponse} from '../../class/apiResponse';
import {ApiService} from '../api.service';
import {ActivatedRoute, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  service$: Observable<ApiResponse>;
  loading: boolean;
  configCatalog: any;
  slug: any;

  constructor(
      private service: ApiService,
      private routeActive?: ActivatedRoute,
  )
  {

  }

  getConfigCatalog(): void
  {
    this.service$ = this.service.getConfigCatalog();
    this.loading = true;
    this.service$.subscribe(data => {
      console.log('config', data);
      if (data.code === 1)
      {
        this.configCatalog = data.details;
        this.loading = false;
        console.log(this.configCatalog);
      }
    }, error => {
      console.log(error);
    });
  }

}

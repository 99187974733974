import { Injectable } from '@angular/core';
import { Category } from '../class/category';
import { cloneDeep, clone } from 'lodash';
import { Item } from '../class/item';
@Injectable({
  providedIn: 'root'
})
export class ItemService {

  newCategory: Category[];
  cloneCategory: Category[];

  filteredList: Category[];

  noItems: any[];

  queryProduct: string;

  constructor() {
    this.newCategory = [];
    this.cloneCategory = [];
    this.filteredList = [];
    this.queryProduct = "";
    this.noItems = [{
      cat_id: 0,
      category_name: "Produto não encontrado",
      category_description: "Infelizmente seu produto não foi encontrado. Por favor digite novamente.",
      items: []
    }]
  }

  loadCategories(category: Category[]) {
    // CLONES ÚNICOS DO ARRAY ORIGINAL
    this.newCategory = cloneDeep(category);
    this.cloneCategory = cloneDeep(category);
  }

  getProduct(product: string): void {
    // O VALOR DO INPUT NO SEARCH COMPONENT SERÃO CARREGADOS NA VARIÁVEL QUERYPRODUCT
    this.queryProduct = product;
    console.log(this.queryProduct);
  }

  filterItems(productQuery: string): Category[] {
    // A FILTRAGEM DE ITEMS SERÁ FEITA COM O USO DOS CLONES E VALOR DO QUERYPRODUCT
    let index = 0;
    this.filteredList = [];

    for (let cat of this.newCategory) {
      this.cloneCategory[index].items = cat?.items?.filter((prod: Item) => {
        return prod.item_name.toLowerCase().includes(productQuery.toLowerCase());
      })
      index++;
    }

    this.filteredList = this.cloneCategory.filter((cat) => {
      return cat?.items?.length > 0;
    })

    if (this.filteredList.length <= 0) {
      this.filteredList = this.noItems;
    }
    return this.filteredList;
  }

  generateList() {
    // MÉTODO QUE IRÁ CHAMAR O MÉTODO FILTERITEMS(), PASSANDO O QUERYPRODUCT COMO A STRING A SER PROCURADA
    this.filteredList = this.filterItems(this.queryProduct);
    // O ARRAY DE CATEGORIAS É RETORNADO
    return this.filteredList;
  }
}

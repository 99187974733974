<form class="navbar-search">
    <div class="input-group">
        <input type="text" class="form-control bg-light text-dark" placeholder="Buscar produto..." aria-label="Search"
            aria-describedby="basic-addon2" name="search" [(ngModel)]="searchProduct" (keyup)="search(searchProduct)" (keyup)="changeCategory()">
        <div class="input-group-append">
            <button class="btn btn-primary" type="submit">
                <i class="feather-search"></i>
            </button>
        </div>
    </div>
</form>
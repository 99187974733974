import { Component, OnInit } from '@angular/core';
import { ItemService } from '../services/item.service';
import { Category } from '../class/category';

@Component({
  selector: 'app-search-product',
  templateUrl: './search-product.component.html',
  styleUrls: ['./search-product.component.css']
})
export class SearchProductComponent implements OnInit {

  searchProduct: string;

  constructor(
    private itemService: ItemService
  ) {
    this.searchProduct = "";
  }

  ngOnInit(): void {
  }

  // O VALOR DO INPUT SERÁ ENVIADO PARA O MÉTODO DE RECUPERAÇÃO NO ITEM SERVICE
  search(product: string) {
    this.itemService.getProduct(product)
  }

  // MÉTODO QUE IRÁ CHAMAR O MÉTODO RESPONSÁVEL POR INICIAR A FILTRAGEM DE ITEMS
  changeCategory() {
    this.itemService.generateList();
  }

}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {

  @Input('bg-color') bgColor: boolean;
  bgColorText: string;

  show = false;
  constructor()
  {
    this.bgColor = true;
    this.bgColorText = '';
  }

  ngOnInit(): void
  {
    if (this.bgColor)
    {
      this.bgColorText = 'bg-primary';
    }
    console.log(this.bgColor);
  }

  showLoading(): void
  {
    this.show = true;
  }

  hideLoading(): void
  {
    this.show = false;
  }

}

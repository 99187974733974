import {Component, OnDestroy, OnInit} from '@angular/core';
import {interval, Observable, Subscription} from 'rxjs';
import {Merchant} from '../class/merchant';
import {ActivatedRoute} from '@angular/router';
import {FunctionsService} from '../services/functions.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../services/api.service';
import {HideService} from '../services/hide.service';
import {MerchantService} from '../services/merchant.service';

@Component({
  selector: 'app-waiting-order',
  templateUrl: './waiting-order.component.html',
  styleUrls: ['./waiting-order.component.css']
})
export class WaitingOrderComponent implements OnInit {

  orderId: any;
  order: any;
  order$: Observable<any>;
  merchantInfo: Merchant;
  verifyInterval: any;

  pixInfo: any;
  isCancelPix: boolean;
  isPixPay: boolean;
  isShowInfoPix: boolean;


  loading: boolean;

  token: string;
  slug: string;

  constructor(
      private service: ApiService,
      private route: ActivatedRoute,
      private functions: FunctionsService,
      private modalService: NgbModal,
      public merchantService: MerchantService
  )
  {
    this.order$ = new Observable<any>();
    this.isCancelPix = false;
    this.isPixPay = false;
    this.isShowInfoPix = false;
    this.loading = false;
    this.token = this.functions.getItemLocal('token');
    if (this.token) {
      this.service.changeToken(this.token);
      console.log(this.token);
    }

    this.slug = this.functions.getItemLocal('slug');
    if (this.slug) {
      this.service.changeSlug(this.slug);
      console.log(this.slug);
    }
  }

  ngOnInit(): void
  {
    if (!this.merchantService.info)
    {
      this.merchantService.verifyInfoMerchant();
    }
    this.orderId = this.route.snapshot.params[`orderId`];
    if (this.orderId !== '')
    {
      this.functions.setItemLocal('orderId', this.orderId);
    }
    this.getOrderInfo();

    HideService.event.emit(true);
    this.verifyInterval = setInterval(() => {
      this.getOrderInfo(false);
    }, 5000);
  }

  copyText(text, msg): void
  {
    navigator.clipboard.writeText(text);
    this.functions.showAlertSuccess(msg);
  }

  copyQrCode(): void
  {
    this.copyText(this.pixInfo.pix_key, 'Código copiado com sucesso!');
  }

  getOrderInfo(loading: boolean = true): void
  {
    this.order$ = this.service.getOrderById(this.orderId);
    this.loading = loading;
    this.order$.subscribe(data => {
      console.log(data);
      if (data.code === 1)
      {
        this.order = data.details.data;
        console.log(this.order);
      }

    }, error => {
      console.log(error);
    }, () => {
      this.loading = false;
    });
  }

  openModalDetailsOrder(modal, id): void
  {
    this.functions.setItemLocal('orderId', id);

    this.modalService.open(modal);
  }

}

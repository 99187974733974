import { Component, OnInit } from '@angular/core';
import {FunctionsService} from '../services/functions.service';
import {ApiService} from '../services/api.service';

@Component({
  selector: 'app-nav-header-payment',
  templateUrl: './nav-header-payment.component.html',
  styleUrls: ['./nav-header-payment.component.css']
})
export class NavHeaderPaymentComponent implements OnInit {

  slug: string;
  token: string;
  constructor(
      public service: ApiService,
      private functions: FunctionsService
  ) { }

  ngOnInit(): void {
    this.slug = this.functions.getItemLocal('slug');
    this.token = this.functions.getItemLocal('token');
  }

  isTable(): boolean
  {
    return this.service.tokenIsTable();
  }
}
